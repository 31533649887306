import {useContext, useEffect, useState} from 'react'

import {useLoaderData} from 'react-router-dom'

import api from 'services/api/index.js'

import ProjectContext from 'contexts/project.js'

import EventsLoading from 'components/display/project/events-loading.js'

import Meta from 'containers/layout/meta.js'
import ProjectContainer from 'containers/project/project-container.js'

export async function projectLoader({params: {projectId}}) {
  const [project, containers, featuredContainers, workflows] = await Promise.all([
    api.getProject(projectId),
    api.getContainers(projectId),
    api.getFeaturedContainers(projectId),
    api.getProjectWorkflows(projectId)
  ])

  return {project, containersCount: containers.length, featuredContainers, workflows}
}

function Project() {
  const {eventSource, project, isUserCanEdit} = useContext(ProjectContext)

  const [eventSourceStatus, setEventSourceStatus] = useState(eventSource?.status)

  const {containersCount, featuredContainers, workflows} = useLoaderData()

  useEffect(() => {
    setEventSourceStatus(eventSource?.status)

    if (eventSource) {
      eventSource.on('status', setEventSourceStatus)
    }

    return () => {
      if (eventSource) {
        eventSource.off('status', setEventSourceStatus)
      }
    }
  }, [eventSource])

  if (isUserCanEdit && eventSourceStatus !== 'connected') {
    return (
      <EventsLoading status={eventSourceStatus}/>
    )
  }

  return (
    <>
      <Meta title={project.title} description={project.description}/>
      <ProjectContainer
        containersCount={containersCount}
        featuredContainers={featuredContainers}
        initialWorkflows={workflows}
      />
    </>
  )
}

export default Project

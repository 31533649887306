/* eslint-disable react/prop-types */

import {useCallback, useEffect, useState} from 'react'

import {CheckOutlined, CloseOutlined, DeleteOutlined, PlusCircleOutlined} from '@ant-design/icons'
import {Space, Typography, Flex, Button, Input, Select, Form, Row, Col, List, Popconfirm, Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const {Title, Text} = Typography

const FirstAttribute = styled(Flex)`
    padding: ${({theme}) => theme.antd.padding}px;
    border: 1px solid ${({theme}) => theme.antd.colorBorder};
    border-radius: ${({theme}) => theme.antd.borderRadius}px;
`

const ColorBubble = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${({theme, color}) => theme.antd[color]};
`

const attributeLabelValidator = (attributeLabels, initialValue, errorMessage) => () => ({
  validator(_, value) {
    if (!value) {
      return Promise.reject(new Error(errorMessage.required))
    }

    if (initialValue === value) {
      return Promise.resolve()
    }

    if (attributeLabels.includes(value)) {
      return Promise.reject(new Error(errorMessage.duplicate))
    }

    return Promise.resolve()
  }
})

function AttributeListItem({
  attribute,
  colors,
  isUsed,
  attributeLabels,
  onCreate,
  onEdit,
  onDelete
}) {
  const {t} = useTranslation('translation', {keyPrefix: 'ProjectSettings.Containers.ProjectContainerAttributs'})
  const {t: tForm} = useTranslation('translation', {keyPrefix: 'ProjectSettings.Containers.ProjectContainerAttributs.AttributeForm'})
  const {t: tCommon} = useTranslation('common')

  const [form] = Form.useForm()

  const [isDeletePopconfirmVisible, setIsDeletePopconfirmVisible] = useState(false)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  // Observe certains champs (ou tous les champs si vous préférez)
  const labelValue = Form.useWatch('label', {form})
  const colorValue = Form.useWatch('color', {form})

  useEffect(() => {
    // Si l’utilisateur n’a touché aucun champ, on ne valide pas (et on laisse le bouton désactivé)
    if (!form.isFieldsTouched()) {
      setIsSaveDisabled(true)
      return
    }

    // Lorsqu'un des champs observés change, on tente de valider
    const validate = async () => {
      try {
        const {label} = await form.validateFields()
        const hasChanged = label !== attribute.label || colorValue !== attribute.color
        setIsSaveDisabled(!label || !hasChanged)
      } catch {
        setIsSaveDisabled(true)
      }
    }

    validate()
  }, [labelValue, colorValue, form, attribute])

  useEffect(() => {
    setIsSaveDisabled(true)
    form.resetFields()
  }, [attribute, form])

  const handleDeleteAttribute = useCallback(async isNewOpen => {
    if (!isNewOpen) {
      setIsDeletePopconfirmVisible(false)
      return
    }

    if (isUsed) {
      setIsDeletePopconfirmVisible(true)
    } else {
      await onDelete(attribute)
    }
  }, [isUsed, onDelete, attribute])

  const handleConfirmDelete = useCallback(async () => {
    await onDelete(attribute)
    setIsDeletePopconfirmVisible(false)
  }, [onDelete, attribute])

  const handleCancelDelete = () => {
    setIsDeletePopconfirmVisible(false)
  }

  const handleInputChange = e => {
    const {value} = e.target
    form.setFieldsValue({label: value})
  }

  const handlePaste = e => {
    e.preventDefault() // Empêche le comportement par défaut
    const pastedText = e.clipboardData.getData('text')
    form.setFieldsValue({label: pastedText})
  }

  const handleBlur = () => {
    const trimmedValue = form.getFieldValue('label').trim()
    form.setFieldsValue({label: trimmedValue})
  }

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={attribute}
      onFinish={attribute.id === 'new' ? onCreate : onEdit}
    >
      <List.Item
        key={attribute.label}
        style={{alignItems: 'baseline'}}
        actions={[
          <Popconfirm
            key={`delete-${attribute.id}`}
            title={t('deletePopConfirm.title')}
            description={t('deletePopConfirm.description')}
            open={isDeletePopconfirmVisible}
            okText={t('deletePopConfirm.okText')}
            cancelText={t('deletePopConfirm.cancelText')}
            okButtonProps={{danger: true}}
            onOpenChange={handleDeleteAttribute}
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
          >
            <Tooltip title={t('actions.delete')}>
              {attribute.id === 'new' ? (
                <Button
                  size='small'
                  icon={<CloseOutlined/>}
                />
              ) : (
                <Button
                  danger
                  size='small'
                  icon={<DeleteOutlined/>}
                />
              )}
            </Tooltip>
          </Popconfirm>,
          <Tooltip
            key={`edit-${attribute.id}`}
            title={t(attribute.id === 'new' ? 'actions.create' : 'actions.edit')}
          >
            <Button
              size='small'
              htmlType='submit'
              disabled={isSaveDisabled}
              icon={<CheckOutlined/>}
            />
          </Tooltip>
        ]}
      >
        <Flex vertical flex={1}>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                style={{marginBottom: 0}}
                name='color'
              >
                <Select>
                  {colors.map(color => (
                    <Select.Option key={color} value={color}>
                      <Space>
                        <ColorBubble color={color}/>
                        <Text>{tCommon(`colors.${color}`)}</Text>
                      </Space>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                style={{marginBottom: 0}}
                name='label'
                rules={[
                  {required: true, message: tCommon('form.requiredField')},
                  attributeLabelValidator(attributeLabels, null, {
                    message: tCommon('form.requiredField'),
                    duplicate: tForm('labelAlreadyExists')
                  })
                ]}
              >
                <Input
                  placeholder={tForm('labelPlaceholder')}
                  onBlur={handleBlur}
                  onChange={handleInputChange}
                  onPaste={handlePaste}
                />
              </Form.Item>
            </Col>
          </Row>
        </Flex>
      </List.Item>
    </Form>
  )
}

function ProjectContainersAttributes({
  attributes,
  usedAttributes,
  colors,
  addAttribute,
  updateAttribute,
  removeAttribute
}) {
  const {t} = useTranslation('translation', {keyPrefix: 'ProjectSettings.Containers.ProjectContainerAttributs'})
  const [attributesList, setAttributesList] = useState(attributes)

  const hasAttributs = attributesList.length > 0
  const attributeLabels = attributes.map(attribute => attribute.label)

  useEffect(() => {
    setAttributesList(attributes)
  }, [attributes])

  const onCreateAttribute = async () => {
    if (!attributesList.some(attribute => attribute.id === 'new')) {
      setAttributesList([...attributesList, {id: 'new', label: '', color: 'blue'}])
    }
  }

  const handleCreateAttribute = async ({color, label}) => {
    await addAttribute({color, label})
  }

  const handleUpdateAttribute = async (index, attribute) => {
    await updateAttribute(index, attribute)
  }

  const handleDeleteAttribute = useCallback(async attributeToDelete => {
    if (attributeToDelete.id === 'new') {
      setAttributesList(attributesList.filter(attribute => attribute.id !== 'new'))
    } else {
      await removeAttribute(attributeToDelete)
    }
  }, [removeAttribute, attributesList])

  return (
    <Flex vertical gap='middle'>
      <Flex justify='space-between' align='center' wrap='wrap'>
        <Title level={3}>{t('title')}</Title>
        {hasAttributs && (
          <Button
            type='dashed'
            icon={<PlusCircleOutlined/>}
            disabled={attributesList.some(attribute => attribute.id === 'new')}
            onClick={onCreateAttribute}
          >
            {t('addAttribute')}
          </Button>
        )}
      </Flex>

      {!hasAttributs && (
        <FirstAttribute vertical align='center' gap='small'>
          <Text>{t('noAttributeHint')}</Text>
          <Button
            type='primary'
            onClick={onCreateAttribute}
          >
            {t('createFirstAttribute')}
          </Button>
        </FirstAttribute>
      )}

      {hasAttributs && (
        <List>
          {attributesList.map(attribute => (
            <AttributeListItem
              key={attribute.id}
              attribute={attribute}
              colors={colors}
              attributeLabels={attributeLabels.filter(label => label !== attribute.label)}
              isUsed={usedAttributes.includes(attribute.id)}
              onCreate={handleCreateAttribute}
              onEdit={newValue => handleUpdateAttribute(attributes.indexOf(attribute), newValue)}
              onDelete={handleDeleteAttribute}
            />
          ))}
        </List>
      )}
    </Flex>
  )
}

export default ProjectContainersAttributes

import {useContext} from 'react'

import {useTranslation} from 'react-i18next'
import {useLoaderData, useParams} from 'react-router-dom'

import api from 'services/api/index.js'

import {UserContext} from 'contexts/user.js'

import Container from 'containers/layout/container.js'
import Meta from 'containers/layout/meta.js'
import ProjectsSection from 'containers/projects/projects-section.js'

export async function userProjectsLoader({params}) {
  const projects = await api.getUserProjects(params.userId)
  return {projects}
}

function UserProjects() {
  const {t} = useTranslation('translation', {keyPrefix: 'Projects'})

  const {user} = useContext(UserContext)

  const {projects} = useLoaderData()
  const {userId} = useParams()

  return (
    <>
      <Meta title={t('pageTitle')}/>
      <Container>
        <ProjectsSection
          isUserMember={userId === user?.id}
          projects={projects}
        />
      </Container>
    </>
  )
}

export default UserProjects

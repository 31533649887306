/* eslint-disable react/prop-types */

import React from 'react'

import styled from 'styled-components'

import ContainerListItem from './containers/container-list-item.js'

const Container = styled.div`
  border: 1px solid ${({theme}) => theme.antd.colorBorder};
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  padding: ${({theme}) => theme.antd.paddingXS}px;
`

function ContainerCard({container}) {
  return (
    <Container>
      <ContainerListItem
        container={container}
      />
    </Container>
  )
}

export default ContainerCard

import {useCallback, useContext, useEffect, useMemo, useTransition} from 'react'

import {Outlet, useLoaderData, useLocation, useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import {getOrganizationUrl, getAccountUrl, getProjectUrl, getUserUrl} from 'helpers/url.js'

import PageContext from 'contexts/page-context.js'
import {ProjectProvider} from 'contexts/project.js'
import {UserContext} from 'contexts/user.js'

import ProjectNavigation from 'containers/project/project-navigation.js'

const NavigationContainer = styled.div`
  padding: ${({theme}) => `0 ${theme.antd.paddingLG}`}px;
  background-color: ${({theme}) => theme.antd.contentBgWhite};
`
NavigationContainer.displayName = 'NavigationContainer'

function ProjectLayout() {
  const {project} = useLoaderData()

  const [, startTransition] = useTransition()

  const {user} = useContext(UserContext)

  const location = useLocation()
  const navigate = useNavigate()

  const {setHeaderNavItems} = useContext(PageContext)

  const getOwnerPath = useCallback(() => {
    if (project.owner.type === 'organization') {
      return getOrganizationUrl(project.owner)
    }

    return user?._id === project.owner._id ? getAccountUrl() : getUserUrl(project.owner)
  }, [project.owner, user])

  useEffect(() => {
    setHeaderNavItems([
      {title: project.owner.displayName, path: getOwnerPath()},
      {title: project.title, path: getProjectUrl(project)}
    ])
  }, [getOwnerPath, project, setHeaderNavItems])

  const {activeKey, isWorkflowOpen} = useMemo(() => {
    const activeKey = location.pathname.split('/')[3] || 'overview'
    return {
      activeKey,
      isWorkflowOpen: activeKey === 'workflows' && location.pathname.split('/')[4]
    }
  }, [location.pathname])

  const handleChange = useCallback(key => {
    startTransition(() => {
      const tab = key === 'overview' ? null : key
      const url = getProjectUrl(project, tab)

      navigate(url)
    })
  }, [navigate, project, startTransition])

  return (
    <ProjectProvider initialValue={project}>
      <NavigationContainer>
        {!isWorkflowOpen && (
          <ProjectNavigation activeKey={activeKey} onChange={handleChange}/>
        )}
      </NavigationContainer>
      <Outlet/>
    </ProjectProvider>
  )
}

export default ProjectLayout

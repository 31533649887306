/* eslint-disable react/prop-types */

import {useMemo} from 'react'

import CenteredContent from 'components/layouts/centered-content.js'

import Forbidden from 'containers/error/forbidden.js'
import NotFound from 'containers/error/not-found.js'
import ServerError from 'containers/error/server-error.js'
import Unauthorized from 'containers/error/unauthorized.js'
import UnexpectedError from 'containers/error/unexpected-error.js'

function Error({code}) {
  const ErrorComponent = useMemo(() => {
    if (code === 401) {
      return Unauthorized
    }

    if (code === 403) {
      return Forbidden
    }

    if (code === 404) {
      return NotFound
    }

    if (code === 500) {
      return ServerError
    }

    return UnexpectedError
  }, [code])

  return (
    <CenteredContent>
      <ErrorComponent/>
    </CenteredContent>
  )
}

export default Error

/* eslint-disable react/prop-types */

import React, {useState} from 'react'

import {Modal, Checkbox, Button, Space, Typography, Flex} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {slugify} from 'util/slug.js'

const {Paragraph, Text} = Typography

const ModalContent = styled(Flex)`
  margin-top: ${({theme}) => theme.antd.marginMD}px;

  .actions {
    text-align: right;
  }
`

function DeleteContainerModal({container, onCancel, onDeprecate, onDelete}) {
  const {t} = useTranslation('translation', {keyPrefix: 'ProjectSettings.Containers.DeleteContainerModal'})

  const [acknowledged, setAcknowledged] = useState(false)

  // Récupération du tableau traduit
  const consequences = t('consequencesList', {returnObjects: true})

  return (
    <Modal
      open
      footer={null}
      title={t('title')}
      onCancel={onCancel}
    >
      <ModalContent
        vertical
        gap='large'
      >
        <div>
          <Paragraph>
            {t('intro')}
            <strong>{`"${container.name}"`}</strong>.
          </Paragraph>
          <Paragraph>{t('consequencesTitle')}</Paragraph>

          <ul>
            {consequences.map(item => (
              <li key={slugify(item)}>{item}</li>
            ))}
          </ul>

          <Paragraph>
            <Text strong>{t('recommendation')}</Text>
          </Paragraph>
          <Checkbox
            checked={acknowledged}
            onChange={e => setAcknowledged(e.target.checked)}
          >
            {t('acknowledge')}
          </Checkbox>
        </div>

        <div className='actions'>
          <Space>
            <Button onClick={onCancel}>{t('cancel')}</Button>
            {!container.isDeprecated && (
              <Button danger disabled={!acknowledged} onClick={onDeprecate}>
                {t('deprecate')}
              </Button>
            )}
            <Button
              danger
              type='primary'
              disabled={!acknowledged}
              onClick={onDelete}
            >
              {t('delete')}
            </Button>
          </Space>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default DeleteContainerModal

/* eslint-disable react/prop-types */

import {QuestionCircleOutlined} from '@ant-design/icons'
import {Button, Flex, Popconfirm, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

const {Text} = Typography

const styles = {
  popConfirmIcon: {
    color: 'red'
  }
}

function DangerZoneItem({
  title,
  description,
  actionLabel,
  onConfirm,
  children
}) {
  const {t} = useTranslation('translation', {keyPrefix: 'DangerZone'})
  return (
    <Flex
      align='center'
      justify='space-between'
      gap='large'
      wrap='wrap'
    >
      <Flex vertical>
        <Text>{title}</Text>
        <Text type='secondary'>{description}</Text>
      </Flex>

      {children || (
        <Popconfirm
          title={t('popConfirm.title')}
          description={t('popConfirm.description')}
          icon={<QuestionCircleOutlined style={styles.popConfirmIcon}/>}
          okText={t('popConfirm.okText')}
          cancelText={t('popConfirm.cancelText')}
          onConfirm={onConfirm}
        >
          <Button danger type='primary'>{actionLabel}</Button>
        </Popconfirm>
      )}
    </Flex>
  )
}

export default DangerZoneItem

/* eslint-disable react/prop-types */

import React, {useState} from 'react'

import {Modal, Button, Typography, Input, Space, Flex} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

const {Paragraph} = Typography

const ModalContent = styled(Flex)`
  margin-top: ${({theme}) => theme.antd.marginMD}px;

  .actions {
    text-align: right;
  }
`

function DeprecateContainerModal({container, onCancel, onDeprecate}) {
  const {t} = useTranslation('translation', {keyPrefix: 'ProjectSettings.Containers.DeprecateContainerModal'})
  const [reason, setReason] = useState('')

  const handleDeprecate = () => {
    onDeprecate(reason)
  }

  return (
    <Modal
      open
      footer={null}
      title={t('title')}
      onCancel={onCancel}
    >
      <ModalContent
        vertical
        gap='large'
      >
        <div>
          <Paragraph>
            {t('intro')}
            <strong>{`"${container.name}"`}</strong>.
          </Paragraph>
          <Paragraph>
            {t('description')}
          </Paragraph>
          <Paragraph>
            <strong>{t('reasonPrompt')}</strong>
          </Paragraph>
          <Input.TextArea
            placeholder={t('placeholder')}
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
        </div>

        <div className='actions'>
          <Space>
            <Button onClick={onCancel}>{t('cancel')}</Button>
            <Button
              danger
              onClick={handleDeprecate}
            >
              {t('deprecate')}
            </Button>
          </Space>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default DeprecateContainerModal

/* eslint-disable react/prop-types */

import {useContext, useMemo} from 'react'

import {FundProjectionScreenOutlined, HomeOutlined, SettingOutlined, TeamOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {getAccountSettingsUrl, getAccountOrganizationsUrl, getAccountUrl, getAccountProjectsUrl, getUserOrganizationsUrl, getUserProjectsUrl, getUserUrl} from 'helpers/url.js'

import {UserContext} from 'contexts/user.js'

import useTabsNavigation from 'hooks/use-tabs-navigation.js'

import Error from 'components/error.js'
import TabsLayout from 'components/layouts/tabs-layout.js'

function UserTabsNavigation({activeTab, children}) {
  const {t} = useTranslation('translation', {keyPrefix: 'UserTabsNavigation'})

  const {user} = useContext(UserContext)
  const {userId} = useParams()

  const tabs = useMemo(() => {
    const isOwner = !userId || (userId && user?._id === userId)

    const tabs = {
      overview: {icon: HomeOutlined, url: isOwner ? getAccountUrl(user) : getUserUrl({_id: userId})},
      projects: {icon: FundProjectionScreenOutlined, url: isOwner ? getAccountProjectsUrl() : getUserProjectsUrl({_id: userId})},
      organizations: {icon: TeamOutlined, url: isOwner ? getAccountOrganizationsUrl() : getUserOrganizationsUrl({_id: userId})}
    }

    if (isOwner) {
      tabs.settings = {icon: SettingOutlined, url: getAccountSettingsUrl()}
    }

    return tabs
  }, [user, userId])

  const {tabsItems, onTabChange} = useTabsNavigation({tabs, t})

  if (!user) {
    return <Error code={401}/>
  }

  return (
    <TabsLayout
      tabs={tabsItems}
      activeTab={activeTab}
      onChangeTab={onTabChange}
    >
      {children}
    </TabsLayout>
  )
}

export default UserTabsNavigation

import {executeRequest} from './util/request.js'

export async function getHighlightedProjects({client}) {
  return executeRequest({
    url: '/projects/featured-datasets',
    method: 'GET',
    client
  })
}

export async function getNews({client}) {
  const posts = await executeRequest({
    url: '/posts/latest',
    method: 'GET',
    client
  })

  return posts
}

export async function subscribeToNewsletter(email) {
  return email
}

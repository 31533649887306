/* eslint-disable react/prop-types */

import {Button, Input, Form, Space, Flex} from 'antd'
import {useTranslation} from 'react-i18next'

import {slugify} from 'util/slug.js'

const transformContainer = container => ({
  ...container,
  attributes: (() => {
    const acc = {}
    for (const attr of (container.attributes || [])) {
      acc[attr.label] = attr.value
    }

    return acc
  })()
})

function ContainerForm({
  container = {},
  projectAttributes = [],
  disabledFields = [],
  onSubmit,
  onClose
}) {
  const {t} = useTranslation('translation', {keyPrefix: 'ProjectSettings.Containers.ContainerForm'})
  const {t: tCommon} = useTranslation('common')

  const [form] = Form.useForm()

  const handleInputChange = e => {
    const {value} = e.target
    const slugifiedValue = slugify(value)
    form.setFieldsValue({name: slugifiedValue})
  }

  const handlePaste = e => {
    e.preventDefault()
    const pastedText = e.clipboardData.getData('text')
    const {value, selectionStart, selectionEnd} = e.target
    const newValue = value.slice(0, selectionStart) + pastedText + value.slice(selectionEnd)
    const slugifiedText = slugify(newValue)
    form.setFieldsValue({name: slugifiedText})
  }

  const handleSubmit = async values => {
    await onSubmit({
      name: values.name,
      attributes: projectAttributes.map(attribute => ({
        ...attribute,
        value: values.attributes[attribute.label]
      })).filter(({value}) => value)
    })
  }

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={transformContainer(container)}
      onFinish={handleSubmit}
    >
      {!disabledFields.includes('name') && (
        <Form.Item
          label={t('nameLabel')}
          name='name'
          rules={[
            {required: true, message: tCommon('form.requiredField')},
            {pattern: /^[a-z\d]+(-[a-z\d]+)*(?<!-)$/, message: t('invalidName')}
          ]}
        >
          <Input
            placeholder={t('namePlaceholder')}
            onChange={handleInputChange}
            onPaste={handlePaste} // Gestion spécifique pour le collage
          />
        </Form.Item>
      )}

      {!disabledFields.includes('attributes') && (
        projectAttributes.map(({id, label}) => (
          <Form.Item
            key={id}
            label={label}
            name={['attributes', label]}
          >
            <Input/>
          </Form.Item>
        ))
      )}
      <Form.Item>
        <Flex justify='end'>
          <Space>
            <Button onClick={onClose}>
              {tCommon('button.cancel')}
            </Button>
            <Button type='primary' htmlType='submit'>
              {tCommon('button.save')}
            </Button>
          </Space>
        </Flex>
      </Form.Item>
    </Form>
  )
}

export default ContainerForm

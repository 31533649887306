import {useContext} from 'react'

import {Navigate, useLoaderData} from 'react-router-dom'

import api from 'services/api/index.js'

import {getAccountUrl} from 'helpers/url.js'

import {UserContext} from 'contexts/user.js'

import AlternateSections from 'components/layouts/alternate-sections.js'

import HighlightedProjectsSection from 'containers/home/highlighted-projects-section.js'
import NewsSection from 'containers/home/news-section.js'
import NewsletterSection from 'containers/home/newsletter-section.js'

export async function homeLoader() {
  const [highlightedProjects, news] = await Promise.all([
    api.getHighlightedProjects(),
    api.getLatestNews()
  ])

  return {highlightedProjects, news}
}

function Home() {
  const {user} = useContext(UserContext)

  const {highlightedProjects, news} = useLoaderData()

  if (user) {
    return <Navigate replace to={getAccountUrl()}/>
  }

  return (
    <AlternateSections>
      <HighlightedProjectsSection highlightedProjects={highlightedProjects}/>
      <NewsSection news={news}/>
      <NewsletterSection/>
    </AlternateSections>
  )
}

export default Home


import {HomeOutlined} from '@ant-design/icons'
import {Result} from 'antd'
import {useTranslation} from 'react-i18next'

import {getHomeUrl} from 'helpers/url.js'

import LinkButton from 'containers/ui/button/link-button.js'

function Forbidden() {
  const {t} = useTranslation()

  return (
    <Result
      status='403'
      title={t('ErrorPage.Forbidden.title')}
      subTitle={t('ErrorPage.Forbidden.subtitle')}
      extra={
        <LinkButton type='primary' href={getHomeUrl()} icon={<HomeOutlined/>}>
          {t('ErrorPage.backHome')}
        </LinkButton>
      }
    />
  )
}

export default Forbidden

import {executeRequest} from './util/request.js'

export async function getUser({client, userId}) {
  return executeRequest({
    url: '/users/' + userId,
    method: 'GET',
    client
  })
}

export async function getUserOrganizations({client, userId}) {
  return executeRequest({
    url: '/users/' + userId + '/organizations',
    method: 'GET',
    client
  })
}

export async function getUserProjects({client, userId}) {
  return executeRequest({
    url: '/users/' + userId + '/projects',
    method: 'GET',
    client
  })
}


import {useCallback, useState} from 'react'

import {DeleteOutlined, PlusCircleOutlined, StarFilled, StarOutlined, StopOutlined} from '@ant-design/icons'
import {message, Modal, Flex, Typography, Button, Divider, Tooltip, theme} from 'antd'
import {uniq} from 'lodash-es'
import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'

import api from 'services/api/index.js'

import ContainerForm from 'components/display/containers/container-form.js'
import ContainersList from 'components/display/containers/containers-list.js'
import DeleteContainerModal from 'components/display/containers/delete-container-modal.js'
import DeprecateContainerModal from 'components/display/containers/deprecate-container-modal.js'
import ProjectContainersAttributes from 'components/ui/project-containers-attributes.js'

const {Title} = Typography

export async function projectContainersLoader({params: {projectId}}) {
  const [project, containers, containerAttributeColors] = await Promise.all([
    api.getProject(projectId),
    api.getContainers(projectId),
    api.getContainerAttributeColors()
  ])

  return {project, containers, containerAttributeColors}
}

function Containers() {
  const {t} = useTranslation(['translation'], {keyPrefix: 'ProjectSettings.Containers'})

  const {token} = theme.useToken()

  const {project, containers: initialContainers, containerAttributeColors} = useOutletContext()

  const [messageApi, contextHolder] = message.useMessage()
  const [containers, setContainers] = useState(initialContainers || [])
  const [attributes, setAttributes] = useState(project.containerAttributes || [])
  const [isContainerModalVisible, setIsContainerModalVisible] = useState(false)
  const [containerToDelete, setContainerToDelete] = useState(null)
  const [containerToDeprecate, setContainerToDeprecate] = useState(null)

  const usedAttributes = uniq(containers.flatMap(container => container?.attributes?.map(({id}) => id)).filter(Boolean))

  const switchFromDeleteToDeprecate = () => {
    setContainerToDelete(container => {
      setContainerToDeprecate(container)
      return null
    })
  }

  const updateProjectAttributes = useCallback(async containerAttributes => {
    try {
      const updatedProject = await api.updateProject(project._id, {containerAttributes})
      setAttributes(updatedProject.containerAttributes)
    } catch (error) {
      messageApi.error(t('updateAttributeFailed', {error: error.message}))
    }
  }, [project._id, messageApi, t])

  const addAttribute = async attribute => {
    try {
      await updateProjectAttributes([...attributes, attribute])
      messageApi.success(t('attributeCreationSuccess'))
    } catch (error) {
      messageApi.success(t('attributeCreationFailed', {error: error.message}))
    }
  }

  const updateAttribute = async (index, attribute) => {
    const updateAttributes = [...attributes]
    updateAttributes[index] = attribute

    try {
      await updateProjectAttributes(updateAttributes)
      messageApi.success(t('attributeUpdateSuccess'))
    } catch (error) {
      messageApi.error(t('attributeUpdateFailed', {error: error.message}))
    }
  }

  const removeAttribute = useCallback(async attribute => {
    const isUsed = usedAttributes.includes(attribute.id)

    await updateProjectAttributes(attributes.filter(a => a.id !== attribute.id))

    if (isUsed) { // If the attribute is used, we need to update the containers
      try {
        const updatedContainers = await api.getContainers(project._id)
        setContainers(updatedContainers)
        messageApi.success(t('attributeRemovalSuccess'))
      } catch (error) {
        messageApi.error(t('attributeRemovalFailed', {error: error.message}))
      }
    }
  }, [project._id, usedAttributes, attributes, updateProjectAttributes, messageApi, t])

  const createContainer = async ({name, attributes}) => {
    try {
      await api.updateContainer(project._id, name, {attributes})
      const updatedContainers = await api.getContainers(project._id)
      setContainers(updatedContainers)
      setIsContainerModalVisible(false)
    } catch (error) {
      messageApi.error(t('containerCreationFailed', {error: error.message}))
    }
  }

  const deleteContainer = useCallback(async () => {
    try {
      await api.deleteContainer(project._id, containerToDelete.name)
      setContainers(containers => containers.filter(c => c._id !== containerToDelete._id))
      setContainerToDelete(null)
    } catch (error) {
      messageApi.error(t('deleteContainerFailed', {error: error.message}))
    }
  }, [project._id, containerToDelete, messageApi, t])

  const deprecateContainer = async deprecationReason => {
    try {
      await api.deprecateContainer(project._id, containerToDeprecate.name, deprecationReason)
      setContainers(prev => {
        const containers = prev.filter(c => c._id !== containerToDeprecate._id)
        return [...containers, {...containerToDeprecate, isDeprecated: true}]
      })
      setContainerToDeprecate(null)
    } catch (error) {
      messageApi.error(t('deprecatedContainerFailed', {error: error.message}))
    }
  }

  const featuredContainer = async container => {
    try {
      const isFeatured = !container.isFeatured
      await api.updateContainer(project._id, container.name, {isFeatured})
      setContainers(prev => {
        const containers = prev.map(c => {
          if (c._id === container._id) {
            return {...c, isFeatured}
          }

          return c
        })

        return containers
      })
    } catch (error) {
      messageApi.error(t('featuredContainerFailed', {error: error.message}))
    }
  }

  return (
    <Flex vertical gap='middle'>
      {contextHolder}

      <Modal
        open={isContainerModalVisible}
        title={t('createContainerFormTitle')}
        footer={null}
        onCancel={() => setIsContainerModalVisible(false)}
      >
        <ContainerForm
          projectAttributes={attributes}
          onSubmit={createContainer}
          onClose={() => setIsContainerModalVisible(false)}
        />
      </Modal>

      {containerToDelete && (
        <DeleteContainerModal
          container={containerToDelete}
          onCancel={() => setContainerToDelete(null)}
          onDeperecate={switchFromDeleteToDeprecate}
          onDelete={deleteContainer}
        />
      )}

      {containerToDeprecate && (
        <DeprecateContainerModal
          container={containerToDeprecate}
          onCancel={() => setContainerToDeprecate(null)}
          onDeprecate={deprecateContainer}
        />
      )}

      <ProjectContainersAttributes
        attributes={attributes}
        usedAttributes={usedAttributes}
        colors={containerAttributeColors}
        addAttribute={addAttribute}
        updateAttribute={updateAttribute}
        removeAttribute={removeAttribute}
      />

      <Divider/>

      <Flex vertical gap='middle'>
        <Flex justify='space-between' align='center' wrap='wrap'>
          <Title level={3}>{t('containersListTitle')}</Title>
          <Button
            type='dashed'
            icon={<PlusCircleOutlined/>}
            onClick={() => setIsContainerModalVisible(true)}
          >
            {t('addContainerLabel')}
          </Button>
        </Flex>

        <ContainersList
          containers={containers}
          attributes={attributes}
          renderActions={container => [
            <Tooltip key='deperecate' title={t('containerActions.deprecate')}>
              <Button
                danger
                disabled={container.isDeprecated}
                icon={<StopOutlined/>}
                onClick={() => setContainerToDeprecate(container)}
              />
            </Tooltip>,
            <Tooltip key='delete' title={t('containerActions.delete')}>
              <Button
                danger
                type='primary'
                icon={<DeleteOutlined/>}
                onClick={() => setContainerToDelete(container)}
              />
            </Tooltip>,
            <Tooltip key='featured' title={t(`containerActions.${container.isFeatured ? 'unfeature' : 'feature'}`)}>
              <Button
                icon={
                  container.isFeatured
                    ? <StarFilled style={{color: token.colorPrimaryYellow}}/>
                    : <StarOutlined/>
                }
                onClick={() => featuredContainer(container)}
              />
            </Tooltip>
          ]}
        />
      </Flex>
    </Flex>
  )
}

export default Containers

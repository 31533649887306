/* eslint-disable react/prop-types */

import {useState} from 'react'

import {ArrowRightOutlined, ClockCircleOutlined, FileOutlined, GoldOutlined, HistoryOutlined, InboxOutlined} from '@ant-design/icons'
import {Alert, Breadcrumb, Button, Empty, Flex, List, message, Modal, Space, Tag, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import {Link, useLoaderData, useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import api from 'services/api/index.js'

import {getProjectFilesContainersUrl, getProjectSettingsUrl} from 'helpers/url.js'

import {getRelativeTimeFormat} from 'util/date.js'

import ContainerAvatar from 'components/display/containers/container-avatar.js'
import ContainerForm from 'components/display/containers/container-form.js'
import DeleteContainerModal from 'components/display/containers/delete-container-modal.js'
import DeprecateContainerModal from 'components/display/containers/deprecate-container-modal.js'
import DangerZoneItem from 'components/display/danger-zone/item.js'
import DangerZone from 'components/display/danger-zone.js'
import Section from 'components/layouts/section.js'
import SidebarLayout from 'components/layouts/sidebar-layout.js'
import FileSize from 'components/ui/file-size.js'
import FilesetViewer from 'components/ui/fileset-viewer.js'

import {Container as ContainerLayout} from 'containers/layout/container.js'
import Meta from 'containers/layout/meta.js'

import {ReactComponent as MagicWand} from 'assets/icons/magic-wand.svg'
import {ReactComponent as TreeFolder} from 'assets/icons/tree-folder.svg'
import AttributesImage from 'assets/images/attributes.svg'

const {Text, Paragraph} = Typography

const BreadcrumbStyled = styled(Breadcrumb)`
  &, .anticon {
    font-size: ${({theme}) => theme.antd.fontSizeHeading4}px;
  }
`

const TreeContainer = styled.div`
  margin: ${({theme}) => theme.antd.margin}px;
`

export async function containerLoader({params: {projectId, containerName}}) {
  const [project, container] = await Promise.all([
    api.getProject(projectId),
    api.getContainer(projectId, containerName)
  ])

  return {project, container}
}

function ContainerSummary({createdAt, updatedAt, fileList}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.Container.summary'})
  const {t: tDate} = useTranslation('common', {keyPrefix: 'date'})

  return (
    <Flex vertical gap='small'>
      <Space>
        <ContainerAvatar type={fileList.type}/>
        <Text strong>{t(`${fileList.type === 'user' ? 'uploaded-by-producer' : 'computed-by-workflow'}`)}</Text>
      </Space>

      <Space>
        <Space>
          <HistoryOutlined/>
          <Text strong>{tDate('updatedSince')}</Text>
        </Space>
        <Text style={{textWrap: 'nowrap'}}>{getRelativeTimeFormat(updatedAt)}</Text>
      </Space>

      <Space>
        <Space>
          <ClockCircleOutlined/>
          <Text strong>{tDate('createdSince')}</Text>
        </Space>
        <Text style={{textWrap: 'nowrap'}}>{getRelativeTimeFormat(createdAt)}</Text>
      </Space>

      <Space>
        <Space>
          <FileOutlined/>
          <Text strong>{t('filesCount')}</Text>
        </Space>
        <Text>{fileList.itemCount}</Text>
      </Space>

      <Space>
        <Space>
          <GoldOutlined/>
          <Text strong>{t('filesSize')}</Text>
        </Space>
        <FileSize style={{textWrap: 'nowrap'}} bytes={fileList.itemsSize}/>
      </Space>
    </Flex>
  )
}

function ContainerAttributes({container, attributes, projectAttributes = [], isEditingEnabled, updateAttributes}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.Container.attributes'})

  const [isAddingAttribute, setIsAddingAttribute] = useState(false)

  const handleSubmit = async ({attributes}) => {
    await updateAttributes({attributes})
    setIsAddingAttribute(false)
  }

  return (
    <Flex vertical gap='small'>
      <Modal
        open={isAddingAttribute}
        title={t('modalTitle')}
        footer={null}
        onCancel={() => setIsAddingAttribute(false)}
      >
        <ContainerForm
          container={container}
          projectAttributes={projectAttributes}
          disabledFields={['name']}
          onClose={() => setIsAddingAttribute(false)}
          onSubmit={handleSubmit}
        />
      </Modal>

      <List
        dataSource={attributes}
        renderItem={attribute => (
          <List.Item>
            <Space>
              <Text strong>{attribute.label}</Text>
              <Tag color={attribute.color}>{attribute.value}</Tag>
            </Space>
          </List.Item>
        )}

      >
        {attributes.length === 0 && (
          <>
            <Empty
              image={AttributesImage}
              description={t('noAttributes')}
              imageStyle={{opacity: 0.6}}
            />

            {isEditingEnabled && (
              <Paragraph type='secondary' style={{textAlign: 'center'}}>
                {t('attributesDescription')}
              </Paragraph>
            )}
          </>
        )}
      </List>

      {isEditingEnabled && (
        <Flex vertical gap='small'>
          <Button
            type='dashed'
            disabled={projectAttributes.length === 0}
            onClick={() => setIsAddingAttribute(true)}
          >
            {t('addAttribute')}
          </Button>

          {projectAttributes.length === 0 && (
            <Alert
              showIcon
              type='info'
              message={t('noAttributesAlertMessage')}
              description={
                <Link to={getProjectSettingsUrl({_id: container.project}, 'containers')}>
                  <Button
                    size='small'
                    icon={<ArrowRightOutlined/>}
                  >
                    {t('noAttributesAlertMessageButtonLabel')}
                  </Button>
                </Link>
              }
            />
          )}
        </Flex>
      )}
    </Flex>
  )
}

function Container() {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.Container'})
  const {t: containersT} = useTranslation('translation', {keyPrefix: 'ProjectSettings.Containers'})
  const {t: containerCardT} = useTranslation('translation', {keyPrefix: 'Project.ContainerCard'})

  const [isDeprecatedModalVisible, setIsDeprecatedModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const navigate = useNavigate()

  const [messageApi, contextHolder] = message.useMessage()

  const {project, ...data} = useLoaderData(containerLoader)

  const [container, setContainer] = useState(data.container)

  const updateContainer = async changes => {
    try {
      await api.updateContainer(project._id, container.name, changes)
      setContainer(prev => ({...prev, ...changes}))
    } catch (error) {
      messageApi.error(t('updateContainerFailed', {error: error.message}))
    }
  }

  const deprecateContainer = async deprecationReason => {
    try {
      await api.deprecateContainer(project._id, container.name, deprecationReason)
      setContainer(prev => ({...prev, isDeprecated: true}))
      setIsDeprecatedModalVisible(null)
    } catch (error) {
      messageApi.error(containersT('deprecatedContainerFailed', {error: error.message}))
    }
  }

  const deleteContainer = async () => {
    try {
      await api.deleteContainer(project._id, container.name)
      navigate(getProjectFilesContainersUrl(project))
    } catch (error) {
      messageApi.error(containersT('deleteContainerFailed', {error: error.message}))
    }
  }

  return (
    <>
      {contextHolder}

      <Meta title={`${container.name} | ${project.title}`}/>

      <SidebarLayout
        main={(
          <Flex vertical gap='middle'>
            <ContainerLayout isBordered background='secondary'>
              <BreadcrumbStyled
                items={[
                  {
                    title: (
                      <Space>
                        <InboxOutlined/>
                        <Link to={getProjectFilesContainersUrl(project)}>
                          Conteneurs
                        </Link>
                      </Space>
                    )
                  },
                  {
                    title: container.name
                  }
                ]}
              />
            </ContainerLayout>

            <Section title={t('treeSectionTitle')} icon={TreeFolder}>
              <TreeContainer>
                {container.fileList ? (
                  <FilesetViewer fileset={container.fileList}/>
                ) : (
                  <Empty description={containerCardT('emptyDescription')}/>
                )}
              </TreeContainer>
            </Section>
          </Flex>
        )}
        sidebar={(
          <ContainerLayout isBordered background='secondary'>
            <Button
              disabled
              type='primary'
              icon={<MagicWand/>}
              size='large'
            >
              {t('actions.createWorkspace')}
            </Button>

            {container.isDeprecated && (
              <Alert
                type='error'
                message={(
                  <Tag color='red'>{containerCardT('deprecated').toUpperCase()}</Tag>
                )}
                description={(
                  <Text type='secondary'>{container.deprecationReason || t('deprecatedDescription')}</Text>
                )}
              />
            )}

            {container.fileList ? (
              <ContainerLayout isBordered background='secondary'>
                <ContainerSummary {...container}/>
              </ContainerLayout>
            ) : (
              <Alert
                type='warning'
                message={<Tag color='orange'>{containerCardT('empty').toUpperCase()}</Tag>}
                description={<Text type='secondary'>{containerCardT('emptyDescription')}</Text>}
              />
            )}

            <ContainerLayout isBordered background='secondary'>
              <ContainerAttributes
                container={container}
                projectAttributes={project.containerAttributes}
                attributes={container.attributes}
                isEditingEnabled={project._permissions.includes('write')}
                updateAttributes={updateContainer}
              />
            </ContainerLayout>

            {project._permissions.includes('write') && (
              <DangerZone>
                {!container.isDeprecated && (
                  <DangerZoneItem
                    isSecondary
                    title={t('dangerZone.deprecate.title')}
                    description={t('dangerZone.deprecate.description')}
                  >
                    {isDeprecatedModalVisible && (
                      <DeprecateContainerModal
                        container={container}
                        onCancel={() => setIsDeprecatedModalVisible(false)}
                        onDeprecate={deprecateContainer}
                      />
                    )}
                    <Button
                      danger
                      onClick={() => setIsDeprecatedModalVisible(true)}
                    >
                      {t('dangerZone.deprecate.buttonLabel')}
                    </Button>
                  </DangerZoneItem>
                )}

                <DangerZoneItem
                  title={t('dangerZone.delete.title')}
                  description={t('dangerZone.delete.description')}
                >
                  {isDeleteModalVisible && (
                    <DeleteContainerModal
                      container={container}
                      onCancel={() => setIsDeleteModalVisible(false)}
                      onDelete={deleteContainer}
                    />)}
                  <Button
                    danger
                    type='primary'
                    onClick={() => setIsDeleteModalVisible(true)}
                  >
                    {t('dangerZone.delete.buttonLabel')}
                  </Button>
                </DangerZoneItem>
              </DangerZone>
            )}
          </ContainerLayout>
        )}
      />
    </>
  )
}

export default Container

import {useContext, useMemo, useState} from 'react'

import {TeamOutlined} from '@ant-design/icons'
import {Flex, message} from 'antd'
import {useTranslation} from 'react-i18next'
import {useLoaderData} from 'react-router-dom'

import api from 'services/api/index.js'

import {UserContext} from 'contexts/user.js'

import Section from 'components/layouts/section.js'
import SidebarLayout from 'components/layouts/sidebar-layout.js'

import Meta from 'containers/layout/meta.js'
import ProjectsSection from 'containers/projects/projects-section.js'
import MembersList from 'containers/user/organizations/members-list.js'
import Summary from 'containers/user/organizations/summary.js'

export async function organizationLoader({params}) {
  const [organization, projects] = await Promise.all([
    api.getOrganization(params.organizationId),
    api.getOrganizationProjects(params.organizationId)
  ])

  return {organization, projects}
}

function Organization() {
  const {t} = useTranslation('translation', {keyPrefix: 'Organization'})

  const {user} = useContext(UserContext)

  const loaderData = useLoaderData()

  const [messageApi, contextHolder] = message.useMessage()

  const [members, setMembers] = useState(loaderData.organization.members)
  const [organization, setOrganization] = useState(loaderData.organization)

  const isUserMember = useMemo(() => loaderData.organization.members.some(({_id}) => user?._id === _id), [loaderData.organization.members, user])

  return (
    <>
      <Meta title={organization.displayName}/>

      {contextHolder}

      <SidebarLayout
        isSidebarFirst
        main={(
          <Flex vertical gap='middle'>
            <ProjectsSection
              projects={loaderData.projects}
              isUserMember={isUserMember}
            />
            <Section
              title={t('membersSectionTitle')}
              icon={TeamOutlined}
            >
              <MembersList
                isUserMember={isUserMember}
                members={members}
                setMembers={setMembers}
                organization={organization}
                setOrganization={setOrganization}
                message={messageApi}
                t={t}
              />
            </Section>
          </Flex>
        )}
        sidebar={(
          <Section>
            <Summary organization={organization}/>
          </Section>
        )}
      />
    </>
  )
}

export default Organization

/* eslint-disable react/prop-types */

import React, {useMemo, useState} from 'react'

import {Button, Col, Flex, Form, Row, Select, Space, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

const {Text} = Typography

const {Option} = Select

function convertAttributesToOptions(containers) {
  const attributeOptions = {}

  for (const container of containers) {
    const attributes = container.attributes || []

    for (const attr of attributes) {
      const {label, value} = attr

      if (label) {
        if (!attributeOptions[label]) {
          attributeOptions[label] = new Set()
        }

        attributeOptions[label].add(value)
      }
    }
  }

  const options = {}
  for (const label of Object.keys(attributeOptions)) {
    options[label] = [...attributeOptions[label]]
  }

  return options
}

function AttributesFilter({containers, resultCount, onChange}) {
  const {t: commonT} = useTranslation('common')
  const {t} = useTranslation('translation', {keyPrefix: 'Project.ProjectContainers'})

  const [form] = Form.useForm()

  const [hasSelectedValues, setHasSelectedValues] = useState(false)

  const attributes = useMemo(() => convertAttributesToOptions(containers), [containers])

  const onValuesChange = (changedValues, values) => {
    setHasSelectedValues(Object.values(values).some(value => value !== undefined))
    onChange(values)
  }

  const onReset = () => {
    form.resetFields()
    setHasSelectedValues(false)
    onChange({})
  }

  return (
    <Flex vertical flex={1} gap='middle'>
      <Text strong>{commonT('form.filter')}</Text>

      <Form
        form={form}
        name='container-attributes-filter'
        layout='vertical'
        onValuesChange={onValuesChange}
      >
        <Row gutter={16}>
          {Object.keys(attributes).map(attribute => (
            <Col
              key={attribute}
              xs={24}
              sm={24}
              md={8}
            >
              <Form.Item label={attribute} name={attribute}>
                <Select allowClear placeholder={attribute}>
                  {attributes[attribute].map(value => (
                    <Option key={`${attribute}-${value}`} value={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ))}
        </Row>

        <Flex justify='end'>
          <Space align='baseline'>
            {hasSelectedValues && (
              <Text>{t('resultsCount', {count: resultCount})}</Text>
            )}

            <Form.Item noStyle>
              <Button htmlType='button' onClick={onReset}>
                {commonT('button.resetFilters')}
              </Button>
            </Form.Item>
          </Space>
        </Flex>
      </Form>
    </Flex>
  )
}

export default React.memo(AttributesFilter)

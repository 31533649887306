import {useCallback, useContext, useEffect, useRef, useState} from 'react'

import React from '@ant-design/icons'
import {theme} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import OperationsContext from 'contexts/operations-context.js'
import ProjectContext from 'contexts/project.js'
import WorkflowContext from 'contexts/workflow-context.js'

import PlaceholderMessage from 'components/display/placeholder-message.js'
import RightPanel from 'components/layouts/right-panel.js'
import SiderLayout from 'components/layouts/sider-layout.js'

import ReactFlowWrapper from 'containers/react-flow/react-flow-wrapper.js'
import OperationSider from 'containers/workflow-editor/operation-sider.js'
import WorkflowSettings from 'containers/workflow-editor/workflow-settings.js'
import WorkspaceSummary from 'containers/workspace/workspace-summary.js'

const PlaceholderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding: ${({theme}) => theme.antd.paddingXL}px;
  margin: 0 auto;
`

const EditorContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`

function WorkflowLayout({children}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.WorkflowLayout'})

  const {token} = theme.useToken()

  const [isCollapsed, setIsCollapsed] = useState(false)

  const {isUserCanEdit} = useContext(ProjectContext)
  const {operations, workflow, workspace} = useContext(WorkflowContext)
  const {selectedOperationId} = useContext(OperationsContext)

  const [maxPanelWidth, setMaxPanelWidth] = useState(400)

  const editorContainerRef = useRef(null)
  const operationIdRef = useRef(null)

  const {selectedOperation} = useContext(OperationsContext)

  const handleToggle = () => {
    if (selectedOperationId) {
      operationIdRef.current = selectedOperationId
    }

    setIsCollapsed(!isCollapsed)
  }

  const handleResizePanel = useCallback(() => {
    if (editorContainerRef.current) {
      setMaxPanelWidth(editorContainerRef.current.offsetWidth - token.siderToggleHeight)
    }
  }, [token.siderToggleHeight])

  useEffect(() => {
    handleResizePanel() // Initial panel max width
  }, [handleResizePanel])

  return (
    <SiderLayout
      isCollapsed={isCollapsed}
      siderContent={children}
      onToggle={handleToggle}
    >
      {operations.length === 0 && !isUserCanEdit ? (
        <PlaceholderContainer>
          <PlaceholderMessage
            message={t('emptyWorkflow')}
            imgSrc='/images/empty-operation-illustration.svg'
            imgAlt={t('emptyWorkflowAlt')}
          />
        </PlaceholderContainer>
      ) : (
        <EditorContainer ref={editorContainerRef}>
          <ReactFlowWrapper isSideMenuOpen={Boolean(children)}/>
          <RightPanel
            maxWidth={maxPanelWidth}
            handleResize={handleResizePanel}
          >
            {selectedOperation ? (
              <OperationSider
                workspaceId={workspace?._id}
                isWorkspaceActive={workspace?.isActive}
                operation={selectedOperation}
              />
            ) : (
              workspace?._id ? (
                <WorkspaceSummary {...workspace}/>
              ) : (
                <WorkflowSettings
                  workflow={workflow}
                  isUserCanEdit={isUserCanEdit}
                />
              ))}
          </RightPanel>
        </EditorContainer>
      )}
    </SiderLayout>
  )
}

WorkflowLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default WorkflowLayout


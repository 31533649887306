/* eslint-disable react/prop-types */

import Icon, {HistoryOutlined, FileOutlined, GoldOutlined} from '@ant-design/icons'
import {Flex, Typography} from 'antd'
import {useTranslation} from 'react-i18next'

import {getRelativeTimeFormat} from 'util/date.js'

import FileSize from 'components/ui/file-size.js'

const {Text} = Typography

function ContainerMetadata({updatedAt, itemCount, itemsSize}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.ContainerCard'})

  return (
    <Flex gap='small' wrap='wrap'>
      <Flex noWrap align='center' gap='small'>
        <Icon component={HistoryOutlined}/>
        <Text style={{textWrap: 'nowrap'}}>{getRelativeTimeFormat(updatedAt)}</Text>
      </Flex>

      <Flex noWrap align='center' gap='small'>
        <Icon component={FileOutlined}/>
        <Text style={{textWrap: 'nowrap'}}>{t('filesCount', {count: itemCount})}</Text>
      </Flex>

      <Flex noWrap align='center' gap='small'>
        <Icon component={GoldOutlined}/>
        <FileSize style={{textWrap: 'nowrap'}} bytes={itemsSize}/>
      </Flex>
    </Flex>
  )
}

export default ContainerMetadata

import {EyeOutlined, InboxOutlined} from '@ant-design/icons'
import {Button, Tooltip, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import {useLoaderData, useNavigate} from 'react-router-dom'

import api from 'services/api/index.js'

import {getProjectFilesContainerUrl} from 'helpers/url.js'

import ContainersList from 'components/display/containers/containers-list.js'
import Section from 'components/layouts/section.js'

import Container from 'containers/layout/container.js'
import Meta from 'containers/layout/meta.js'

import {ReactComponent as MagicWand} from 'assets/icons/magic-wand.svg'

const {Paragraph} = Typography

export async function projectFileContainersLoader({params: {projectId}}) {
  const [project, containers] = await Promise.all([
    api.getProject(projectId),
    api.getContainers(projectId)
  ])

  return {project, containers: containers.filter(container => container.fileList)}
}

function FileContainers() {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.ProjectContainers'})

  const {project, containers} = useLoaderData()

  const navigate = useNavigate()

  const renderActions = container => {
    const actions = [
      <Tooltip key='show-files' title={t('containerActions.showFiles')}>
        <Button
          icon={<EyeOutlined/>}
          onClick={() => navigate(getProjectFilesContainerUrl(project, container))}
        />
      </Tooltip>
    ]

    if (project._permissions.includes('write')) {
      actions.push(
        <Tooltip key='create-workspace' title={t('containerActions.createWorkspace')}>
          <Button
            // TODO: Add onClick handler
            disabled={container.isDeprecated}
            icon={<MagicWand/>}
          />
        </Tooltip>
      )
    }

    return actions
  }

  return (
    <Container>
      <Meta
        title={t('pageTitle', {projectTitle: project.title})}
        description={t('pageDescription', {projectTitle: project.title})}
      />
      <Section
        title={t('sectionTitle')}
        icon={InboxOutlined}
        link={null}
      >
        <Paragraph>
          {t('hint')}
        </Paragraph>

        <ContainersList
          containers={containers}
          attributes={project.containerAttributes}
          renderActions={renderActions}
        />
      </Section>
    </Container>
  )
}

export default FileContainers

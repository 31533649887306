import {useContext, useMemo} from 'react'

import {ApartmentOutlined, HomeOutlined, InboxOutlined, SettingOutlined} from '@ant-design/icons'
import {Tabs} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'

import ProjectContext from 'contexts/project.js'

function ProjectNavigation({activeKey, onChange}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.ProjectNavigation'})

  const {isUserCanEdit} = useContext(ProjectContext)

  const items = useMemo(() => {
    const items = [
      {key: 'overview', label: t('overview'), icon: <HomeOutlined/>},
      {key: 'workflows', label: t('workflows'), icon: <ApartmentOutlined/>},
      {key: 'containers', label: t('containers'), icon: <InboxOutlined/>}
    ]

    if (isUserCanEdit) {
      items.push({key: 'settings', label: t('settings'), icon: <SettingOutlined/>})
    }

    return items
  }, [isUserCanEdit, t])

  return (
    <Tabs
      items={items}
      activeKey={activeKey}
      tabBarStyle={{margin: 0}}
      onTabClick={onChange}
    />
  )
}

ProjectNavigation.propTypes = {
  activeKey: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default ProjectNavigation

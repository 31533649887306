/* eslint-disable react/prop-types */

import React from 'react'

import {UserOutlined, RobotOutlined} from '@ant-design/icons'
import {Avatar, Tooltip, theme} from 'antd'
import {useTranslation} from 'react-i18next'

function ContainerAvatar({type}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.ContainerCard'})
  const {token} = theme.useToken()

  return (
    <div style={{minWidth: 20, minHeight: 20}}>
      <Tooltip title={t(`${type === 'user' ? 'uploaded-by-producer' : 'computed-by-workflow'}`)}>
        <Avatar
          size='small'
          style={{backgroundColor: token.colorPrimary}}
          icon={type === 'user' ? <UserOutlined/> : <RobotOutlined/>}
        />
      </Tooltip>
    </div>
  )
}

export default ContainerAvatar

/* eslint-disable react/prop-types */

import {useCallback, useContext, useEffect, useState} from 'react'

import {FileOutlined} from '@ant-design/icons'
import {Flex, message, Skeleton, theme} from 'antd'
import {orderBy} from 'lodash-es'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import api from 'services/api/index.js'

import {getProjectFilesContainersUrl} from 'helpers/url.js'

import {getTimeSince} from 'util/date.js'

import ProjectContext from 'contexts/project.js'

import ContainerCard from 'components/display/container-card.js'
import PlaceholderMessage from 'components/display/placeholder-message.js'
import ProjectMeta from 'components/display/project/project-meta.js'
import ProjectSummary from 'components/display/project/project-summary.js'
import Section from 'components/layouts/section.js'
import SidebarLayout from 'components/layouts/sidebar-layout.js'
import FilesetViewerModal from 'components/ui/fileset-viewer-modal.js'

import ProjectIntegrations from 'containers/project/project-integrations.js'
import WorkflowsList from 'containers/workflows/workflows-list.js'

const ProjectSectionsWrapper = styled(Flex)`
  background-color: ${({theme}) => theme.antd.contentBgWhite};
  padding: ${({theme}) => theme.antd.padding}px;
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
`

function ProjectContainer({containersCount, featuredContainers, initialWorkflows}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project'})

  const {project} = useContext(ProjectContext)

  const {token} = theme.useToken()

  const [messageApi, contextHolder] = message.useMessage()

  const [avatar, setAvatar] = useState()
  const [isAvatarLoading, setIsAvatarLoading] = useState(true)
  const [openedFileList, setOpenedFileList] = useState(null)
  const [workflows, setWorkflows] = useState(initialWorkflows)

  const deleteWorkflow = useCallback(async workflowId => {
    try {
      await api.deleteWorkflow(workflowId)
      setWorkflows(prevWorkflows => prevWorkflows.filter(workflow => workflow._id !== workflowId))
    } catch (error) {
      messageApi.error(t('Workflows.deletingFailed', {error}))
    }
  }, [messageApi, t])

  useEffect(() => {
    async function fetchAvatar() {
      const avatar = api.getAvatarURL(project.owner, 'small')

      setIsAvatarLoading(false)
      setAvatar(avatar)
    }

    fetchAvatar()
  }, [project.owner])

  return (
    <>
      {contextHolder}
      <SidebarLayout
        main={(
          <Flex vertical gap={token.marginSM}>
            <ProjectSummary
              {...project}
            />
            <WorkflowsList
              isOwner={project._permissions.includes('write')}
              workflows={workflows}
              deleteWorkflow={deleteWorkflow}
            />
          </Flex>

        )}
        sidebar={
          <ProjectSectionsWrapper vertical gap={token.margin}>
            <Skeleton active avatar loading={isAvatarLoading} size='large'>
              <ProjectMeta
                avatar={avatar}
                ownerType={project.owner.type}
                fullName={project.owner.displayName}
                creationDate={getTimeSince(project._created)}
                lastUpdate={getTimeSince(project._updated)}
                isDataset={project.type === 'dataset'}
                dataset={project?.dataset || {}}
              />
            </Skeleton>

            <Section
              title={t('ProjectContainers.sectionTitle')}
              icon={FileOutlined}
              link={containersCount > featuredContainers.length ? {
                href: getProjectFilesContainersUrl(project),
                label: t('ProjectContainers.filesLinkLabel')
              } : null}
            >
              {featuredContainers.length === 0 && (
                <PlaceholderMessage
                  message={t('ProjectContainers.noFilePlaceholder')}
                  imgSrc='/images/container-illustration.svg'
                  imgAlt={t('ProjectContainers.noFileImgAlt')}
                />
              )}

              <Flex vertical gap='small'>
                {orderBy(featuredContainers, ['updatedAt'], ['desc']).map(container => (
                  <ContainerCard key={container._id} container={container}/>
                ))}
              </Flex>

              {openedFileList && (
                <FilesetViewerModal
                  fileset={openedFileList}
                  onClose={() => setOpenedFileList(null)}
                />
              )}

            </Section>

            <ProjectIntegrations/>
          </ProjectSectionsWrapper>
        }
      />
    </>
  )
}

export default ProjectContainer

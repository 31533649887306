import {LoginOutlined} from '@ant-design/icons'
import {Result} from 'antd'
import {useTranslation} from 'react-i18next'

import {getSignInUrl} from 'helpers/url.js'

import LinkButton from 'containers/ui/button/link-button.js'

function Unauthorized() {
  const {t} = useTranslation()

  return (
    <Result
      status='403'
      title={t('ErrorPage.Unauthorized.title')}
      subTitle={t('ErrorPage.Unauthorized.subtitle')}
      extra={
        <LinkButton href={getSignInUrl()}>
          <LoginOutlined/><span>{t('common:button.login')}</span>
        </LinkButton>
      }
    />
  )
}

export default Unauthorized

import {useEffect, useState} from 'react'

import {HomeOutlined} from '@ant-design/icons'
import {Result, Typography} from 'antd'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {getHomeUrl} from 'helpers/url.js'

import HttpError from 'util/http-error.js'

import LinkButton from 'containers/ui/button/link-button.js'

const {Paragraph} = Typography

const Text = styled(Typography.Text)`
  font-size: 16px;
`

function UnexpectedError({error}) {
  const {t} = useTranslation()
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    async function getErrorMessage() {
      if (error instanceof HttpError) {
        const response = await error.response.json()
        setErrorMessage(response.message)
      } else {
        setErrorMessage(error.message)
      }
    }

    getErrorMessage()
  }, [error])

  return (
    <Result
      status='error'
      title={t('ErrorPage.UnexpectedError.title')}
      subTitle={t('ErrorPage.UnexpectedError.subtitle')}
      extra={
        <LinkButton type='primary' href={getHomeUrl()} icon={<HomeOutlined/>}>
          {t('ErrorPage.backHome')}
        </LinkButton>
      }
    >
      <div className='desc'>
        <Paragraph>
          <Text strong>
            {t('ErrorPage.UnexpectedError.errorCause')}
          </Text>
        </Paragraph>
        <Paragraph>
          {errorMessage} {t('ErrorPage.UnexpectedError.errorCode', {code: error.code})}
        </Paragraph>
      </div>
    </Result>
  )
}

UnexpectedError.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired
}

export default UnexpectedError

/* eslint-disable react/prop-types */

import {useCallback, useEffect, useState} from 'react'

import {DownOutlined} from '@ant-design/icons'
import {Flex} from 'antd'
import styled from 'styled-components'

import api from 'services/api/index.js'

import {filesetToTree, getAllPaths} from 'util/file-tree.js'

import DirectoryTree from 'components/ui/directory-tree.js'
import Actions from 'components/ui/fileset-explorer/actions.js'
import Counters, {countFoldersAndFiles} from 'components/ui/fileset-explorer/counters.js'
import TreeItem from 'components/ui/fileset-explorer/tree-item.js'

const fieldNames = {
  title: 'title',
  key: 'key',
  downloadUrl: 'downloadUrl',
  children: 'children',
  size: 'size'
}

const FilesetViewerStyled = styled(Flex)`
  height: inherit;
  margin: ${({theme}) => theme.antd.margin}px 0;

`

function FilesetViewer({fileset}) {
  const [expandedKeys, setExpandedKeys] = useState([])
  const [checkedKeys, setCheckedKeys] = useState([])
  const [files, setFiles] = useState([])

  const paths = getAllPaths(files)
  const isAllKeysChecked = checkedKeys.length === paths.length

  const onExpand = expandedKeys => {
    setExpandedKeys(expandedKeys)
  }

  const onCheckAll = () => {
    if (isAllKeysChecked) {
      setCheckedKeys([])
    } else {
      setCheckedKeys(getAllPaths((files)))
    }
  }

  const onCheck = checkedKeysValue => {
    setCheckedKeys(checkedKeysValue)
  }

  const titleRender = useCallback(({key, ...nodeProps}) => <TreeItem key={key} {...nodeProps}/>, [])

  useEffect(() => {
    async function fetchFiles() {
      const items = await api.getFileListItems(fileset._id)
      setFiles(filesetToTree(items))
    }

    fetchFiles()
  }, [fileset._id])

  return (
    <FilesetViewerStyled vertical gap='middle'>
      <Actions
        treeData={files}
        isAllKeysChecked={isAllKeysChecked}
        checkedKeys={checkedKeys}
        onCheckAll={onCheckAll}
      />

      <DirectoryTree
        checkable
        selectedKeys={[]}
        expandedKeys={expandedKeys}
        switcherIcon={<DownOutlined/>}
        titleRender={titleRender}
        fieldNames={fieldNames}
        checkedKeys={checkedKeys}
        treeData={files}
        onExpand={onExpand}
        onCheck={onCheck}
      />

      <Flex justify='end'>
        <Counters {...countFoldersAndFiles(files)}/>
      </Flex>
    </FilesetViewerStyled>
  )
}

export default FilesetViewer

import {HomeOutlined} from '@ant-design/icons'
import {Result} from 'antd'
import {useTranslation} from 'react-i18next'

import {getHomeUrl} from 'helpers/url.js'

import LinkButton from 'containers/ui/button/link-button.js'

function NotFound() {
  const {t} = useTranslation()

  return (
    <Result
      status='404'
      title='404'
      subTitle={t('ErrorPage.NotFound')}
      extra={(
        <LinkButton type='primary' href={getHomeUrl()} icon={<HomeOutlined/>}>
          {t('ErrorPage.backHome')}
        </LinkButton>
      )}
    />
  )
}

export default NotFound

/* eslint-disable react/prop-types */

import {useCallback} from 'react'

import {EditOutlined, PlayCircleOutlined} from '@ant-design/icons'
import {Button, Divider, Flex, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import api from 'services/api/index.js'

import SectionExtendedLayout from 'components/layouts/section-extended-layout.js'

import WorkflowSettingsForm from 'containers/workflow/workflow-settings-form.js'
import WorkspacesList from 'containers/workspaces-list.js'

import WorkflowDescriptionSection from './workflow-description-section.js'
import WorkflowExecutionsSection from './workflow-executions-section.js'
import WorkflowMetadataSection from './workflow-metadata-section.js'
import WorkspacesSection from './workspaces-section.js'

const WorkflowSummaryStyled = styled.div`
  padding: ${({theme}) => theme.antd.paddingMD}px;
`
WorkflowSummaryStyled.displayName = 'WorkflowSummaryStyled'

const Title = styled(Typography.Text)`
  width: fit-content;
  margin: 0;
  font-size: ${({theme}) => theme.antd.fontSizeHeading3}px;
`

function WorkflowSummary({workflow, isUserCanEdit, selectSection}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.WorkflowEditor'

  const handleUpdate = useCallback(async changes => {
    await api.updateWorkflow(workflow._id, changes)
    selectSection(null)
  }, [workflow._id, selectSection])

  const handleEdit = () => selectSection(() => (
    <WorkflowSettingsForm
      initialValues={workflow}
      onUpdate={handleUpdate}
      onClose={() => selectSection(null)}
    />
  ))

  const showWorkspacesList = workspaces => selectSection(() => (
    <SectionExtendedLayout
      title='Workspaces'
      onClose={() => selectSection(null)}
    >
      <WorkspacesList workspaces={workspaces}/>
    </SectionExtendedLayout>
  ))

  return (
    <WorkflowSummaryStyled>
      <Flex vertical gap='middle'>
        <Flex gap='small' justify='space-between' align='baseline'>
          <Title>{workflow.title}</Title>
          {isUserCanEdit && (
            <Button
              icon={<EditOutlined/>}
              shape='square'
              type='text'
              onClick={handleEdit}
            />
          )}
        </Flex>
        {isUserCanEdit && (
          <Flex vertical gap='small'>
            <Button
              disabled
              icon={<PlayCircleOutlined/>}
              type='dashed'
            >
              {t(`${keyPrefix}.execute`)}
            </Button>
          </Flex>
        )}
      </Flex>

      <Divider/>

      <Flex vertical gap='large'>
        <WorkflowDescriptionSection
          description={workflow.description}
          isUserCanEdit={isUserCanEdit}
          edit={handleEdit}
        />
        {isUserCanEdit && (
          <WorkspacesSection
            showWorkspacesList={showWorkspacesList}
          />
        )}
        <WorkflowExecutionsSection/>

        <WorkflowMetadataSection
          createdAt={workflow._created}
          updatedAt={workflow._updated}
          operationsUpdatedAt={workflow.operationsUpdatedAt}
          operationsUpdatedBy={workflow.operationsUpdatedBy}
        />
      </Flex>
    </WorkflowSummaryStyled>
  )
}

export default WorkflowSummary

/* eslint-disable react/prop-types */

import React from 'react'

import {LinkOutlined} from '@ant-design/icons'
import {Space, Flex, Typography, Tag} from 'antd'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {getProjectFilesContainerUrl} from 'helpers/url.js'

import ContainerAvatar from 'components/display/containers/container-avatar.js'
import ContainerMetadata from 'components/display/containers/container-metadata.js'

const {Title} = Typography

const styles = {
  noMargin: {
    margin: 0
  }
}

const AttributesList = styled(Flex)`
    & .ant-tag {
        margin-inline-end: 0;
    }
`

function ContainerListItem({container, actions}) {
  const {t} = useTranslation(['translation', 'common'])
  const keyPrefix = 'Project.ContainerCard'
  const {name, fileList, updatedAt, attributes, isDeprecated} = container
  const containerUrl = getProjectFilesContainerUrl({_id: container.project}, container)

  return (
    <Flex flex={1} justify='space-between' align='start'>
      <Flex vertical gap='large'>
        <Space>
          <Link to={containerUrl}>
            <Title
              underline
              level={5}
              style={styles.noMargin}
              copyable={{
                text: `${process.env.REACT_APP_HOSTNAME}${containerUrl}`,
                icon: [<LinkOutlined key='copy'/>],
                tooltips: [t('common:copyableTooltip.copy'), t('common:copyableTooltip.copied')]
              }}
            >
              {name}
            </Title>
          </Link>

          {(!fileList || fileList.length === 0) && (
            <Tag color='orange' style={styles.noMargin}>{t(`${keyPrefix}.empty`).toUpperCase()}</Tag>
          )}

          {isDeprecated && (
            <Tag color='red' style={styles.noMargin}>{t(`${keyPrefix}.deprecated`).toUpperCase()}</Tag>
          )}
        </Space>

        <Flex vertical gap='middle'>
          <Flex justify='space-between' align='center' gap='small'>
            {fileList && (
              <>
                <ContainerMetadata
                  updatedAt={updatedAt}
                  itemCount={fileList.itemCount}
                  itemsSize={fileList.itemsSize}
                />

                <ContainerAvatar type={fileList.type}/>
              </>
            )}

          </Flex>

          <AttributesList wrap='wrap' gap={4}>
            {attributes?.map(({id, value, color}) => (
              <Tag key={id} color={color}>{value}</Tag>
            ))}
          </AttributesList>
        </Flex>
      </Flex>

      <Space.Compact>
        {actions}
      </Space.Compact>
    </Flex>
  )
}

export default ContainerListItem

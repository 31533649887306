/**
 * Class representing an HTTP error.
 * It extends the standard JavaScript Error class to include HTTP-specific information.
 */
export default class HttpError extends Error {
  constructor(response) {
    // Initialize the Error class with the response status text
    super(response.statusText)

    // Initialize the HTTP error-specific properties
    this.name = 'HttpError'
    this.code = response.status
    this.response = response

    this.validationMessages = []
  }

  // Retrieves and returns a custom error message based on the HTTP response.
  async getErrorMessage() {
    if (!this.message) {
      try {
        const body = await this.response.json()
        this.message = body?.message
        this.validationMessages = body?.details || []
      } catch (error) {
        console.error('Error parsing response JSON:', error)
      }
    }

    return {message: this.message, validationMessages: this.validationMessages}
  }
}

/* eslint-disable react/prop-types */
import {Divider, Flex, Space, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import api from 'services/api/index.js'

import {getProjectUrl} from 'helpers/url.js'

import {getRelativeTimeFormat} from 'util/date.js'

import OrganizationAvatar from 'components/display/avatar/organization-avatar.js'
import UserAvatar from 'components/display/avatar/user-avatar.js'

const {Title, Text} = Typography

const HighlightedProjectStyled = styled(Flex)`
    border-radius: ${({theme}) => theme.antd.borderRadius}px;
    box-shadow: ${({theme}) => theme.antd.boxShadow};
    padding: ${({theme}) => theme.antd.padding}px;
    height: 100%;
    background-color: ${({theme}) => theme.antd.contentBgWhite};
    cursor: pointer;
`

const styles = {
  divider: {
    margin: 0
  }
}

function HighlightedProject(props) {
  const {t} = useTranslation('translation', {keyPrefix: 'Home.HighlightedProjectsSection.HighlightedProject'})
  const navigate = useNavigate()
  const {owner, title, _updated} = props
  const Avatar = owner.type === 'user' ? UserAvatar : OrganizationAvatar

  return (
    <HighlightedProjectStyled
      vertical
      gap='small'
      onClick={() => navigate(getProjectUrl(props))}
    >
      <Flex flex={1} justify='center'>
        <Avatar
          avatar={api.getAvatarURL(owner, 'large')}
          size={100}
          shape='circle'
        />
      </Flex>

      <Title level={5}>{title}</Title>

      <Divider style={styles.divider}/>

      <Flex vertical>
        <Space>
          <Text strong>{t('author')}</Text>
          <Text type='secondary'>{owner.displayName}</Text>
        </Space>
        <Space>
          <Text strong>{t('updated')}</Text>
          <Text type='secondary'>{getRelativeTimeFormat(_updated)}</Text>
        </Space>
      </Flex>
    </HighlightedProjectStyled>
  )
}

export default HighlightedProject

/* eslint-disable react/prop-types */

import React, {useCallback, useEffect, useState} from 'react'

import {Flex, List} from 'antd'
import {orderBy} from 'lodash-es'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import AttributesFilter from 'components/display/containers/attributes-filter.js'
import ContainerListItem from 'components/display/containers/container-list-item.js'
import PlaceholderMessage from 'components/display/placeholder-message.js'

const FilterContainer = styled(Flex)`
  background-color: ${({theme}) => theme.token.colorGray70};
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  padding: ${({theme}) => theme.antd.paddingSM}px;
`

function ContainersList({containers, attributes = [], renderActions}) {
  const {t} = useTranslation('translation', {keyPrefix: 'Project.ProjectContainers'})

  const [filteredContainers, setFilteredContainers] = useState([])

  useEffect(() => {
    setFilteredContainers(orderBy(containers, ['fileListUpdatedAt', 'updatedAt'], ['asc', 'asc']))
  }, [containers])

  const onFilterChange = useCallback(filters => {
    // If filters is empty or all value is nul or undefined, show all containers
    if (Object.keys(filters).length === 0 || Object.values(filters).every(value => !value)) {
      setFilteredContainers(containers)
      return
    }

    const filteredContainers = containers.filter(container => {
      if (!container.attributes) {
        return false
      }

      const {attributes} = container

      return Object.entries(filters).every(([label, value]) => {
        if (!value) {
          return true
        }

        return attributes.some(attr => attr.label === label && attr.value === value)
      })
    })

    setFilteredContainers(filteredContainers)
  }, [containers])

  return (
    <>
      {containers.length > 0 && attributes.length > 0 && containers.some(({attributes}) => attributes?.length > 0) && (
        <FilterContainer>
          <AttributesFilter
            containers={containers}
            resultCount={filteredContainers.length}
            onChange={onFilterChange}
          />
        </FilterContainer>
      )}

      {filteredContainers.length === 0 ? (
        <PlaceholderMessage
          message={t('noFilePlaceholder')}
          imgSrc='/images/container-illustration.svg'
          imgAlt={t('noFileImgAlt')}
        />
      ) : (
        <List
          dataSource={filteredContainers}
          renderItem={container => (
            <List.Item key={container._id}>
              <ContainerListItem
                container={container}
                actions={renderActions(container)}
              />
            </List.Item>
          )}
          locale={{emptyText: t('noContainerFound')}}
          pagination={{
            align: 'end',
            hideOnSinglePage: true,
            pageSize: 20
          }}
        />
      )}
    </>
  )
}

export default React.memo(ContainersList)

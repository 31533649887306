/* eslint-disable react/prop-types */

import {useCallback, useContext, useEffect, useState} from 'react'

import {PlusCircleOutlined, RetweetOutlined} from '@ant-design/icons'
import {Button, Flex, message, Radio, Skeleton, Space, Switch, Typography} from 'antd'
import {orderBy} from 'lodash-es'
import styled from 'styled-components'

import api from 'services/api/index.js'

import OperationParamsContext from 'contexts/operation-params-context.js'
import ProjectContext from 'contexts/project.js'
import WorkflowContext from 'contexts/workflow-context.js'

import withEditable from 'hoc/with-editable.js'

import useEventSource from 'hooks/use-event-source.js'

import FilesetViewerModal from 'components/ui/fileset-viewer-modal.js'

import FilesetModal from 'containers/fileset-modal.js'
import FilesetCardController from 'containers/project/fileset-card-controller.js'

const RadioStyled = styled(Radio)`
  > span:nth-child(2) { 
    width: 100%;
  }

  > span > div {
      border: 1px solid ${({theme, checked}) => checked ? theme.antd.colorPrimary : theme.antd.contentBgLight};
  }

  > span > div:hover {
      border-color: ${({theme}) => theme.antd.colorPrimary};
  }
`

function Display({name}) {
  const {params} = useContext(OperationParamsContext)

  const [fileList, setFileList] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    async function fetchFileList() {
      const fileLists = await api.getFileList(params[name])
      setFileList(fileLists)
    }

    fetchFileList()
  }, [params, name])

  return (
    <Skeleton active loading={!fileList}>
      {fileList && (
        <FilesetCardController
          fileset={fileList}
          onOpen={() => setIsOpen(true)}
        />
      )}

      {isOpen && (
        <FilesetViewerModal
          fileset={fileList}
          onClose={() => setIsOpen(false)}
        />
      )}
    </Skeleton>
  )
}

function Field({name, t}) {
  const {project} = useContext(ProjectContext)
  const {workspace} = useContext(WorkflowContext)
  const {initialParams, params, updateParams} = useContext(OperationParamsContext)

  const selectedFileListId = params[name] || initialParams[name]

  const [isLoading, setIsLoading] = useState(false)
  const [fileLists, setFileLists] = useState([])
  const [openFileList, setOpenFileList] = useState(null)
  const [filesetInstance, setFilesetInstance] = useState(null)
  const [isFileListShown, setIsFileListShown] = useState(false)
  const [isSelectionShown, setIsSelectionShown] = useState(Boolean(!selectedFileListId))

  const eventSource = useEventSource({url: `/file-lists/${openFileList?._id}/events`, enabled: Boolean(openFileList)})

  const [messageApi, contextHolder] = message.useMessage()

  useEffect(() => {
    async function updateFileList(message) {
      if (message.type === 'file-list:closed') {
        const fileLists = await api.getProjectFileLists(project._id)
        setFileLists(fileLists)
      }
    }

    if (eventSource) {
      eventSource.on('message', updateFileList)
    }

    return () => {
      if (eventSource) {
        eventSource.off('message', updateFileList)
      }
    }
  }, [eventSource, project._id])

  useEffect(() => {
    async function fetchFileLists() {
      setIsLoading(true)
      const fileLists = await api.getProjectFileLists(project._id)

      setFileLists(fileLists)
      setIsLoading(false)
    }

    fetchFileLists()
  }, [project._id])

  useEffect(() => {
    if (openFileList && openFileList.status !== 'closed') {
      const filesetInstance = api.getFileListInteractiveInstance(openFileList._id)
      setFilesetInstance(filesetInstance)
    } else {
      setFilesetInstance(null)
    }
  }, [openFileList])

  const onSelectFileList = useCallback(async event => {
    const {value} = event.target
    await updateParams({fileList: value, resetSeed: new Date()}) // Temporary solution to reset the seed
    setIsSelectionShown(false)
  }, [updateParams])

  const addFileList = useCallback(async () => {
    try {
      const fileList = await api.createWorkspaceFileList(workspace._id)
      setFileLists([...fileLists, fileList])
      setOpenFileList(fileList)
      await updateParams({fileList: fileList._id, resetSeed: new Date()}) // Temporary solution to reset the seed
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    }
  }, [workspace._id, fileLists, messageApi, updateParams])

  const onCloseFileset = useCallback(async fileListId => {
    await api.closeFileList(fileListId)
    await updateParams({fileList: fileListId, resetSeed: new Date()}) // Temporary solution to reset the seed
    setOpenFileList(null)
    setIsSelectionShown(false)
  }, [updateParams])

  const onCloseFilesetModal = useCallback(async () => {
    await updateParams({fileList: selectedFileListId, resetSeed: new Date()}) // Temporary solution to reset the seed
    setOpenFileList(null)
    setIsSelectionShown(false)
  }, [selectedFileListId, updateParams])

  return (
    <Flex vertical gap='large'>
      {contextHolder}

      {openFileList && openFileList.status === 'closed' && (
        <FilesetViewerModal
          fileset={openFileList}
          onClose={() => setOpenFileList(null)}
        />
      )}

      {filesetInstance && (
        <FilesetModal
          fileset={filesetInstance}
          onConfirm={() => onCloseFileset(openFileList._id)}
          onClose={onCloseFilesetModal}
        />
      )}

      {isSelectionShown ? (
        <>
          <Button
            type='primary'
            icon={<PlusCircleOutlined/>}
            onClick={addFileList}
          >
            {t('params.createNewFileList')}
          </Button>

          <Skeleton active loading={isLoading}>
            {fileLists.length > 0 && (
              <Flex vertical gap='small'>
                <Space>
                  <Typography.Text>{t('params.switchFileListLabel')}</Typography.Text>
                  <Switch checked={isFileListShown} onChange={() => setIsFileListShown(!isFileListShown)}/>
                </Space>
                {isFileListShown && (
                  <Radio.Group
                    value={selectedFileListId}
                    onChange={onSelectFileList}
                  >
                    <Flex vertical gap='small'>
                      {orderBy(fileLists, ['updatedAt'], ['desc']).map(fileList => (
                        <RadioStyled
                          key={fileList._id}
                          value={fileList._id}
                          checked={selectedFileListId === fileList._id}
                        >
                          <FilesetCardController
                            key={fileList._id}
                            fileset={fileList}
                            onCloseFileset={onCloseFileset}
                            onOpen={setOpenFileList}
                          />
                        </RadioStyled>
                      ))}
                    </Flex>
                  </Radio.Group>
                )}
              </Flex>
            )}
          </Skeleton>

          {selectedFileListId && (
            <Button
              onClick={() => setIsSelectionShown(false)}
            >
              {t('common:button.cancel')}
            </Button>
          )}
        </>
      ) : (
        <>
          <Skeleton active loading={isLoading || !selectedFileListId}>
            {fileLists.length > 0 && selectedFileListId && (
              <FilesetCardController
                fileset={fileLists.find(({_id}) => _id === selectedFileListId)}
                onCloseFileset={onCloseFileset}
                onOpen={setOpenFileList}
              />
            )}
          </Skeleton>

          <Button
            icon={<RetweetOutlined/>}
            onClick={() => setIsSelectionShown(true)}
          >
            {t('common:button.edit')}
          </Button>
        </>
      )}
    </Flex>
  )
}

export default withEditable(Field, Display)
